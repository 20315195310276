import { FC, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Spin } from 'antd';
import 'antd/dist/antd.min.css';
import { PrivateRoute } from 'components/PrivateRoute';
import { accountAdminMenuKeyState, siteState, uiState } from 'recoil/atoms';
import { pageMetadata } from 'shared/pageMetadata';
import { ACLCheck } from 'shared/Helpers';
import { useAuth } from 'hooks';
import './AppVantage.scss';
import { LoadingOutlined } from '@ant-design/icons';
import { useSocket } from 'hooks/useSocket';
import { CacheBuster } from '@piplup/cache-buster';
// import SmartbenchDashboard from 'pages/dashboard/SmartbenchDashboard';
import { DealCreationSuccess } from './pages/deal/client/deal-creation/DealCreationSuccess';
// import ClientDeals from './pages/deal/client';
// import { Storage } from 'shared/storage';
// import { PortofinoFlyer } from './components/atoms/PortofinoFlyer';
// import { NODE_ENV } from './shared/constants';
// import SiteAdministration from 'pages/site-administration';
import SiteAdminDashboard from 'pages/site-administration/SiteAdminCards';
import Renewals from 'pages/renewals/Renewals';

// Layouts
const MainLayout = lazy(() => import('./layout/MainLayout'));

// Client-Facing Pages
const ClientDashboard = lazy(() => import('./pages/dashboard/ClientDashboard'));
const SupplierDynamics = lazy(() => import('./pages/supplier-dynamics/SupplierDynamicsHome'));
const SiteAdmin = lazy(() => import('./pages/account-admin'));
const UserAdmin = lazy(() => import('./pages/user-admin'));
const VerifyEmail = lazy(() => import('./pages/verify-email'));
// const Renewals = lazy(() => import('./pages/renewals/Renewals'));
const Research = lazy(() => import('./pages/Research/Research'));
const PinPoint = lazy(() => import('./pages/PinPoint'));
const HistoricData = lazy(() => import('./pages/historic-data'));
const DocumentManagement = lazy(() => import('./pages/documents'));
const DealDetails = lazy(() => import('./pages/deal/client'));
const SubmitPurchase = lazy(() => import('./pages/deal/purchase-analysis'));
const PortfolioCalendar = lazy(() => import('./pages/portfolio/PortfolioCalendar'));
const UploadBulkDealsPage = lazy(() => import('./pages/deal/client/multiple-deals/UploadBulkDealsPage'));
const UploadSingleDeal = lazy(() => import('./pages/deal/single-contract'));
const UploadMultipleDeals = lazy(() => import('./pages/deal/client/multiple-deals/MultipleDealPage'));
// SmartBench Pages
const SmartbenchDashboard = lazy(() => import('./pages/dashboard/SmartbenchDashboard'));
const SmartBenchDashboardIndex = lazy(() => import('./pages/dashboard/SmartBenchDashboardIndex'));
const Contract = lazy(() => import('./pages/contract/Contract'));
const DealView = lazy(() => import('./pages/deal/internal/InternalDeals'));
const Contracts = lazy(() => import('./pages/contract/Contracts'));
const ReportsIndex = lazy(() => import('./pages/reports/ReportsIndex'));
const Reports = lazy(() => import('./pages/reports/Reports'));
const ReportBuilder = lazy(() => import('./pages/reports/ReportBuilder'));
const ReportExport = lazy(() => import('./pages/reports/ReportExport'));
const SkuBenchmark = lazy(() => import('./pages/benchmark/ProductPricingBenchmark'));
const DealConfiguration = lazy(() => import('./pages/account-settings'));
const Integrations = lazy(() => import('./pages/account-settings/Integrations'));

// site admin pages
// const Vendors = lazy(() => import('pages/site-administration/Vendors'));
// const Products = lazy(() => import('./pages/site-administration/Products'));
// const PriceLists = lazy(() => import('./pages/site-administration/PriceLists'));
// const SupplierDynamicsAdmin = lazy(() => import('./pages/site-administration/SupplierDynamicsAdmin'));
// const Resellers = lazy(() => import('./pages/site-administration/Resellers'));
// const Clients = lazy(() => import('./pages/site-administration/Clients'));
// const Users = lazy(() => import('./pages/site-administration/Users'));
// const InquiryAdministration = lazy(() => import('./pages/site-administration/InquiryAdministration'));
// const InquiryWorkbench = lazy(() => import('./pages/site-administration/InquiryWorkbench'));
// const SitePermissions = lazy(() => import('./pages/site-administration/Permissions'));
// const Roles = lazy(() => import('./pages/site-administration/Roles'));
// const ContentModeration = lazy(() => import('./pages/site-administration/ContentModeration'));
// const ReviewModeration = lazy(() => import('./pages/site-administration/ReviewModeration'));
// const ProductAccuracy: FC = lazy(() => import('./pages/site-administration/ProductAccuracy'));
// const BannerAdministration = lazy(() => import('./pages/site-administration/BannerAdministration'));
import Vendors from './pages/site-administration/Vendors';
import Products from './pages/site-administration/Products';
import PriceLists from './pages/site-administration/PriceLists';
import SupplierDynamicsAdmin from './pages/site-administration/SupplierDynamicsAdmin';
import Resellers from './pages/site-administration/Resellers';
import Clients from './pages/site-administration/Clients';
import Users from './pages/site-administration/Users';
import InquiryAdministration from './pages/site-administration/InquiryAdministration';
import InquiryWorkbench from './pages/site-administration/InquiryWorkbench';
import SitePermissions from './pages/site-administration/Permissions';
import Roles from './pages/site-administration/Roles';
import ContentModeration from './pages/site-administration/ContentModeration';
import ReviewModeration from './pages/site-administration/ReviewModeration';
import ProductAccuracy from './pages/site-administration/ProductAccuracy';
import BannerAdministration from './pages/site-administration';

/**
 * antIcon represents an Ant Design loading icon element with customized style and animation.
 *
 * @type {React.ReactElement}
 */
const antIcon: React.ReactElement = <LoadingOutlined style={{ fontSize: 96 }} spin />;
// export const SOCKET_URL = process.env.REACT_APP_EXPRESS_URL || 'http://localhost:3030';

/**
 * The App variable represents the main application component.
 * It is a functional component that renders the routes and handles the application state.
 *
 * @returns {React.ReactElement}
 */
const App: FC = (): React.ReactElement => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const setUi = useSetRecoilState(uiState);
  const setAccountAdminMenu = useSetRecoilState(accountAdminMenuKeyState);
  const setSite = useSetRecoilState(siteState);
  // const site = useRecoilValue(siteState);
  useSocket();

  useEffect((): void => {
    const res = pageMetadata(location.pathname);
    // Reset accountAdminMenuKey to default
    setAccountAdminMenu('account_summary');
    // localStorage.setItem('lastLocation', location.pathname);
    const showIntro = localStorage.getItem('heroBanner');
    setUi({
      title: res.title,
      breadcrumbs: res.breadcrumbs,
      menu: res.menu,
      menuKey: '',
      showIntro: !showIntro || showIntro === 'opened'
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect((): void => {
    setSite(location.pathname.startsWith('/sb') ? 'smartbench' : 'vantage');
  }, [location, setSite]);

  useEffect(() => {
    /**
     * Set width of Freshdesk iframe to 104px to avoid blocking screen access to the right of
     * the widget.
     *
     * @type {HTMLIFrameElement}
     */
    const freshDeskIframe: HTMLElement | null = document.getElementById('launcher-frame');
    if (freshDeskIframe) {
      freshDeskIframe.style.width = '104px';
    }
  }, []);

  /**
   * Represents a loading application component.
   *
   * @type {React.ReactElement}
   */
  const loadingApp: React.ReactElement = (
    <div className="min-h-screen bg-[#ECEFF1] flex justify-center items-center">
      <Spin indicator={antIcon} tip="Securing app .." />
    </div>
  );

  /**
   * The siteAdministrationRoutes variable represents the routes for the site administration section of the application.
   * It is a React element that defines the routes using the React Router library.
   *
   * @type {React.ReactElement}
   */
  const SiteAdministrationRoutes: React.ReactElement = (
    <Route path="site_administration">
      <Route index element={<PrivateRoute as={SiteAdminDashboard} />} />
      <Route path="suppliers" element={<PrivateRoute as={Vendors} />} />
      <Route path="products" element={<PrivateRoute as={Products} />} />
      <Route path="price_lists" element={<PrivateRoute as={PriceLists} />} />
      <Route path="product_accuracy" element={<PrivateRoute as={ProductAccuracy} />} />
      <Route path="supplier_dynamics" element={<PrivateRoute as={SupplierDynamicsAdmin} />} />
      <Route path="resellers" element={<PrivateRoute as={Resellers} />} />
      <Route path="clients" element={<PrivateRoute as={Clients} />} />
      <Route path="users" element={<PrivateRoute as={Users} />} />
      <Route path="triage" element={<PrivateRoute as={InquiryAdministration} />} />
      <Route path="workbench" element={<PrivateRoute as={InquiryWorkbench} />} />
      <Route path="permissions" element={<PrivateRoute as={SitePermissions} />} />
      <Route path="roles" element={<PrivateRoute as={Roles} />} />
      <Route path="community_moderation" element={<PrivateRoute as={ContentModeration} />} />
      <Route path="review_moderation" element={<PrivateRoute as={ReviewModeration} />} />
      <Route path="banner_administration" element={<PrivateRoute as={BannerAdministration} />} />
    </Route>
  );

  if (!isAuthenticated && ACLCheck(['read:client_app'])) {
    return loadingApp;
  }

  /**
   * Enables cache busting if running in production or test environment.
   *
   * @type {boolean}
   */
  // const enableCacheBuster: boolean = NODE_ENV === 'production' || NODE_ENV === 'test' || NODE_ENV === 'development';

  return (
    <CacheBuster loading={loadingApp} verbose={true} enabled={true}>
      <>
        {/*{!ACLCheck(['read:portofino']) && !ACLCheck(['read:smartbench_access']) && <PortofinoFlyer />}*/}

        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route path={'/'} element={<PrivateRoute as={ClientDashboard} />} />
            <Route path="/user_admin" element={<PrivateRoute as={UserAdmin} />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
            {ACLCheck(['read:pinpoint']) && <Route path="/pinpoint" element={<PrivateRoute as={PinPoint} />} />}
            {ACLCheck(['read:deal_calendar_all', 'read:deal_calendar_company']) && (
              <Route path="/deal_calendar" element={<PrivateRoute as={Renewals} />} />
            )}
            <Route path="/portfolio_calendar" element={<PrivateRoute as={PortfolioCalendar} />} />
            {ACLCheck(['read:own_inquiry']) && (
              <Route path="/research/:community_type" element={<PrivateRoute as={Research} />} />
            )}
            {ACLCheck(['read:supplier_dynamics']) && (
              <Route path="/supplier_dynamics" element={<PrivateRoute as={SupplierDynamics} />} />
            )}
            <Route path="/subscription_purchases" element={<PrivateRoute as={HistoricData} />} />
            <Route path="/submit_purchase" element={<PrivateRoute as={SubmitPurchase} />} />
            <Route path="/upload_deals" element={<PrivateRoute as={UploadBulkDealsPage} />} />
            <Route path="/add_single_deal" element={<PrivateRoute as={UploadSingleDeal} />} />
            <Route path="/add_multiple_deals" element={<PrivateRoute as={UploadMultipleDeals} />} />
            {/*<Route path="/single_contract" element={<PrivateRoute as={SingleContract} />} />*/}
            <Route path="/deal_details/:deal_id" element={<PrivateRoute as={DealDetails} />} />
            {ACLCheck(['read:npi_docs']) && (
              <Route path="resources" element={<PrivateRoute as={DocumentManagement} />} />
            )}
            {ACLCheck(['update:banner_ad', 'update:site_wide_admin']) && SiteAdministrationRoutes}
            {ACLCheck(['read:all_companies', 'read:all_company_users']) && (
              <Route path="account_admin" element={<PrivateRoute as={SiteAdmin} />} />
            )}
            {ACLCheck(['read:site_wide_admin', 'update:banner_ad']) && (
              <Route path="banner_admin" element={<PrivateRoute as={BannerAdministration} />} />
            )}
            <Route path="/deal_creation_success" element={<PrivateRoute as={DealCreationSuccess} />} />
            <Route path="/deal_creation_success/:contract_id" element={<PrivateRoute as={DealCreationSuccess} />} />
            <Route path="/account_settings" element={<PrivateRoute as={DealConfiguration} />} />
            <Route path="/integrations" element={<PrivateRoute as={Integrations} />} />

            {ACLCheck(['read:smartbench_access']) && (
              <Route path="sb" element={<PrivateRoute as={SmartBenchDashboardIndex} />}>
                <Route index element={<PrivateRoute as={SmartbenchDashboard} />} />
                <Route path="contracts" element={<PrivateRoute as={Contracts} />} />
                <Route path="contract/:contract_id" element={<PrivateRoute as={Contract} />} />
                <Route path="deal/:contract_id" element={<PrivateRoute as={DealView} />} />
                <Route path="reports" element={<PrivateRoute as={ReportsIndex} />}>
                  <Route index element={<PrivateRoute as={Reports} />} />
                  <Route path=":id" element={<PrivateRoute as={ReportExport} />} />
                  <Route path="builder">
                    <Route path=":report_id" element={<PrivateRoute as={ReportBuilder} />} />
                  </Route>
                </Route>
                <Route path="benchmarks/product" element={<PrivateRoute as={SkuBenchmark} />} />
              </Route>
            )}
          </Route>
        </Routes>
      </>
    </CacheBuster>
  );
};

export default App;
