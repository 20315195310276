import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { RenewalNotificationRequest } from 'components/renewals/RenewalAlertsModal';

/**
 * Update or delete note_reaction
 *
 * @param data
 */
export const createRenewalNotifications = async (data: RenewalNotificationRequest[]) => {
  try {
    const res = await axios.put(`/v2/renewal/notifications`, data);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
