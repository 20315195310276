import { FC } from 'react';
import { Menu, MenuProps } from 'antd';
import { ApartmentOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ACLCheck } from 'shared/Helpers';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { accountAdminMenuKeyState, accountSettingsMenuKeyState, clientEmailDomainsState } from 'recoil/atoms';

type MenuItem = Required<MenuProps>['items'][number];
type MenuItems = MenuProps['items'];

/**
 * This should match the order of the Carousel elements in SiteAdmin.tsx file
 */
export const SITE_ADMIN_MENU: MenuItems = [
  { key: 'supplier', label: 'Suppliers', disabled: true },
  { key: 'products', label: 'Products', disabled: true },
  { key: 'price_lists', label: 'Price Lists', disabled: true },
  { key: 'supplier_dynamics', label: 'Supplier Dynamics', disabled: true },
  { key: 'clients', label: 'Clients', disabled: true },
  { key: 'users', label: 'Users', disabled: true },
  { key: 'note_moderation', label: 'Moderate Content', disabled: true },
  { key: 'review_moderation', label: 'Moderate Reviews', disabled: true },
  { key: 'inquiry_admin', label: 'Inquiry Triage', disabled: true },
  { key: 'inquiry_workbench', label: 'Inquiry Workbench', disabled: true },
  { key: 'resellers', label: 'Resellers', disabled: true },
  { key: 'permissions', label: 'Site Permissions', disabled: true },
  { key: 'roles', label: 'User Roles', disabled: true },
  { key: 'extraction_accuracy', label: 'Extraction Accuracy', disabled: true }
];

export const AccountAdminMenu: FC<MenuProps> = (props) => {
  const [accountAdminMenuKey, setAccountAdminMenuState] = useRecoilState(accountAdminMenuKeyState);
  const clientEmailDomains = useRecoilValue(clientEmailDomainsState);

  const ACCOUNT_ADMIN_MENU: Array<MenuItem> = [
    { key: 'account_summary', label: 'Client Summary', icon: <ApartmentOutlined /> },
    { key: 'client_entitlements', label: 'Users', icon: <TeamOutlined />, disabled: clientEmailDomains?.length === 0 }
  ];

  const onAccountAdminMenuSelect = (item: any) => {
    setAccountAdminMenuState(item.key);
  };

  return (
    <Menu
      className="h-full"
      mode="inline"
      items={ACCOUNT_ADMIN_MENU}
      defaultSelectedKeys={[accountAdminMenuKey]}
      selectedKeys={[accountAdminMenuKey]}
      onSelect={onAccountAdminMenuSelect}
      {...props}
    />
  );
};

/**
 * AccountSettingsMenu component
 *
 * This functional component renders a menu for account settings, which includes items
 * such as "Custom Fields" and "Integrations". The selected menu item is managed using
 * Recoil state and can be updated by selecting a different item from the menu.
 *
 * @component
 * @param {MenuProps} props - The properties passed to the Menu component.
 * @returns {React.ReactElement} The rendered AccountSettingsMenu component.
 */
export const AccountSettingsMenu: FC<MenuProps> = (props: MenuProps): React.ReactElement => {
  const [accountSettingsMenuKey, setAccountSettingsMenuState] = useRecoilState(accountSettingsMenuKeyState);
  const MENU_ITEMS: Array<MenuItem> = [
    { key: 'custom_fields', label: 'Custom Fields' },
    { key: 'integrations', label: 'Integrations' }
  ];

  /**
   * Handles the selection of an item from the account settings menu.
   * Updates the state with the key of the selected menu item.
   *
   * @param {Object} params - The parameters for the function.
   * @param {any} params.key - The key of the selected menu item.
   * @returns {void}
   */
  const onAccountSettingsMenuSelect = ({ key }: { key: any }): void => {
    setAccountSettingsMenuState(key);
  };

  return (
    <Menu
      className="h-full"
      mode="inline"
      items={MENU_ITEMS}
      defaultSelectedKeys={[accountSettingsMenuKey]}
      selectedKeys={[accountSettingsMenuKey]}
      onSelect={onAccountSettingsMenuSelect}
      {...props}
    />
  );
};

export const UserAdminMenu: FC<MenuProps> = (props) => {
  const USER_ADMIN_MENU: Array<MenuItem> = [{ key: 'account_summary', label: 'User Profile', icon: <UserOutlined /> }];

  return (
    <Menu className="h-full" items={USER_ADMIN_MENU} mode="inline" defaultSelectedKeys={['user_profile']} {...props} />
  );
};

export const SiteAdminMenu: React.FC<MenuProps> = (props) => {
  /**
   * Enable SITE ADMIN MENU ITEMS BASED ON ACL
   */
  if (Array.isArray(SITE_ADMIN_MENU)) {
    /**
     * Enable PRODUCTS menu item if the user has at least read permission
     */
    if (ACLCheck(['read:all_supplier_products', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'products') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable USERS menu item if the user has at least read permission
     */
    if (ACLCheck(['read:all_company_users', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'users') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable PRICE LIST menu item if the user has at least read permission
     */
    if (ACLCheck(['read:all_supplier_price_lists', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'price_lists') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable SUPPLIER DYNAMICS menu item if the user has at least read permission
     */
    if (ACLCheck(['read:supplier_dynamics_admin', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'supplier_dynamics') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable CONTENT MODERATION menu item if the user has at least read permission
     */
    if (ACLCheck(['read:all_supplier_community', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'note_moderation') {
          Object.assign(item, { disabled: false });
        }
      });
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'review_moderation') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable INQUIRY RESEARCH ADMINISTRATION menu item if the user has at least read permission
     */
    if (ACLCheck(['read:inquiry_admin', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'inquiry_admin') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable INQUIRY RESEARCH REPLY menu item if the user has at least read permission
     */
    if (ACLCheck(['read:inquiry_reply', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'inquiry_workbench') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable SUPPLIERS & RESELLERS menu item if the user has at least read permission
     */
    if (ACLCheck(['read:suppliers_resellers', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'supplier') {
          Object.assign(item, { disabled: false });
        }
      });
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'resellers') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    if (ACLCheck(['read:client_admin', 'read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'clients') {
          Object.assign(item, { disabled: false });
        }
      });
    }

    /**
     * Enable ROLES & PERMISSIONS & EXTRACTION ACCURACY menu item if the user has at least read permission
     */
    if (ACLCheck(['read:site_wide_admin'])) {
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'permissions') {
          Object.assign(item, { disabled: false });
        }
      });
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'roles') {
          Object.assign(item, { disabled: false });
        }
      });
      SITE_ADMIN_MENU.map((item: ItemType) => {
        if (item?.key === 'extraction_accuracy') {
          Object.assign(item, { disabled: false });
        }
      });
    }
  }

  return (
    <Menu
      mode="inline"
      style={{ height: '100%' }}
      items={SITE_ADMIN_MENU}
      defaultSelectedKeys={['supplier']}
      {...props}
    />
  );
};
